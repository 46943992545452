import { render, staticRenderFns } from "./DashboardEditProfile.vue?vue&type=template&id=20a36945&scoped=true&"
import script from "./DashboardEditProfile.vue?vue&type=script&lang=js&"
export * from "./DashboardEditProfile.vue?vue&type=script&lang=js&"
import style0 from "./DashboardEditProfile.vue?vue&type=style&index=0&id=20a36945&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a36945",
  null
  
)

export default component.exports