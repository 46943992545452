import { required, minLength, helpers } from 'vuelidate/lib/validators';

const isHasUppercaseLetter = helpers.regex('isHasUppercaseLetter', /[A-Z]+/);
const isHasNumber = helpers.regex('isHasNumber', /\d+/);

export default {
  validations: {
    oldPassword: {
      required,
      minLength: minLength(8),
      isHasUppercaseLetter,
      isHasNumber,
    },
  },
  computed: {
    isOldPasswordError() {
      return (
        (this.$v.oldPassword.$dirty && this.$v.oldPassword.$invalid) ||
        !!this.getErrors.current_password
      );
    },
    oldPasswordErrorText() {
      if (!this.$v.oldPassword.required) {
        return 'The field is required';
      }
      if (!this.$v.oldPassword.minLength) {
        return 'Password must be at least 8 characters';
      }
      if (!this.$v.oldPassword.isHasUppercaseLetter) {
        return 'Password must be at least with 1 upper case letter';
      }
      if (!this.$v.oldPassword.isHasNumber) {
        return 'Password must be at least with 1 number';
      }
      if (!!this.getErrors.current_password) {
        return this.getErrors.current_password[0];
      }
    },
    oldPasswordStrength() {
      return {
        minLength:
          this.$v.oldPassword.required && this.$v.oldPassword.minLength,
        isHasUppercaseLetter:
          this.$v.oldPassword.required &&
          this.$v.oldPassword.isHasUppercaseLetter,
        isHasNumber:
          this.$v.oldPassword.required && this.$v.oldPassword.isHasNumber,
      };
    },
  },
};
