<template>
  <div class="change-password">
    <h3 class="settings__top-block-title">Change password</h3>
    <div class="settings__top-block-password">
      <div class="password__block">
        <LabelField id="password-old" title="Old password" />
        <TextField
          id="password-old"
          ref="textField_1"
          v-model.trim="oldPassword"
          name="password"
          type="password"
          is-password
          :password-strength="oldPasswordStrength"
          :error="isOldPasswordError"
          :error-txt="oldPasswordErrorText"
          tooltip-text="Password must be at least <span>8 characters</span> with <span>1 upper case letter</span> and <span>1 number</span>."
          @focus="resetServerError('current_password')"
        />
      </div>
      <div class="password__block">
        <LabelField id="new-password" title="New password" />
        <TextField
          id="new-password"
          ref="textField_2"
          v-model.trim="password"
          name="password"
          type="password"
          is-password
          :password-strength="passwordStrength"
          :error="isPasswordError"
          :error-txt="passwordErrorText"
          tooltip-text="Password must be at least <span>8 characters</span> with <span>1 upper case letter</span> and <span>1 number</span>."
          @focus="resetServerError('new_password')"
        />
      </div>
      <div class="password__block">
        <LabelField id="confirm-password" title="Password confirmation" />
        <TextField
          id="confirm-password"
          ref="textField_3"
          v-model.trim="confirmPassword"
          name="password"
          type="password"
          is-password
          :password-strength="confirmPasswordStrength"
          :error="isConfirmPasswordError"
          :error-txt="confirmPasswordErrorText"
          tooltip-text="Password must be at least <span>8 characters</span> with <span>1 upper case letter</span> and <span>1 number</span>."
          @focus="resetServerError('new_password_confirmation')"
        />
      </div>
    </div>
    <div class="settings__top-block-button">
      <ButtonBase :loading="confirmLoader" @click="saveNewPassword"
        >Save</ButtonBase
      >
    </div>
  </div>
</template>

<script>
import settingsChangePasswordValidation from '@/mixins/validation/forms/settingsChangePassword.js';
import { mapGetters } from 'vuex';
import LabelField from '@/elements/LabelField';

export default {
  components: { LabelField },
  mixins: [settingsChangePasswordValidation],
  props: {},
  data() {
    return {
      oldPassword: '',
      password: '',
      confirmPassword: '',
      confirmLoader: false,
    };
  },
  computed: {
    ...mapGetters(['getProfile', 'getErrors']),
  },
  methods: {
    async saveNewPassword() {
      if (this.isInvalidForm) {
        this.showValidationErrors();
        return;
      }
      this.confirmLoader = true;
      const res = await this.$store.dispatch('changePassword', {
        current_password: this.oldPassword,
        new_password: this.password,
        new_password_confirmation: this.confirmPassword,
      });
      this.confirmLoader = false;
      if (res) {
        this.hideValidationErrors();
        this.resetPasswords();
        this.$refs.textField_1.closeEyes();
        this.$refs.textField_2.closeEyes();
        this.$refs.textField_3.closeEyes();
      }
    },
    resetPasswords() {
      this.oldPassword = '';
      this.password = '';
      this.confirmPassword = '';
    },
  },
};
</script>

<style lang="sass" scoped>
.change-password
  display: flex
  flex-direction: column
  background-color: $background-profile-color
  padding: 2.4rem
  border-radius: 8px
  gap: 2.4rem

.settings__top-block-title
  @include xl
    text-align: center

.settings__top-block-password
  display: grid
  grid-template-columns: repeat(3, 1fr)
  gap: 2.4rem
  @include xl
    grid-template-columns: 1fr
    width: 100%
    max-width: 35rem
    margin: 0 auto
    justify-content: center
    flex-wrap: wrap
  & div
    width: 100%
    min-width: 32rem
    @include m
      min-width: 15rem

.settings__top-block-button
  margin-left: auto
  @include xl
    margin: 0 auto
</style>
