<template>
  <div class="change-password">
    <h3 class="settings__top-block-title">Connect Wallet</h3>
    <div class="settings__top-block-profile">
      <div class="signup-form__item">
        <LabelField
          id="bsc-recieve"
          class="signup-form__label"
          title="BSC Receive Address"
        />
        <TextField
          id="bsc-recieve"
          v-model.trim="bscRecieveAddress"
          name="bsc-recieve"
          required
          class="signup-form__input"
          :error="!!isBSCRecieveAddressError"
          :error-txt="BSCRecieveAddressErrorText"
          tooltip-text=""
          type="text"
          connect-wallet-btn="metamask"
          @connect-metamask-wallet="connectMetamaskBSCWallet"
        />
      </div>
      <div class="signup-form__item">
        <LabelField
          id="srcx-recieve"
          class="signup-form__label"
          title="SRCX Receive Address"
        />
        <TextField
          id="srcx-recieve"
          v-model.trim="srcxRecieveAddress"
          name="srcx-recieve"
          required
          class="signup-form__input"
          :error="!!isSrcxRecieveAddressError"
          :error-txt="srcxRecieveAddressErrorText"
          tooltip-text=""
          type="text"
          connect-wallet-btn="metamask"
          @connect-metamask-wallet="connectMetamaskSRCXWallet"
        />
      </div>
      <div class="signup-form__item">
        <LabelField
          id="source-recieve"
          class="signup-form__label"
          title="SOURCE Receive Address"
        />
        <TextField
          id="source-recieve"
          v-model.trim="sourceRecieveAddress"
          name="source-recieve"
          required
          class="signup-form__input"
          tooltip-text=""
          type="text"
          :error="!!isSourceRecieveAddressError"
          :error-txt="sourceRecieveAddressErrorText"
          connect-wallet-btn="keplr"
          @change="convertSourceAddressOnMobile"
          @connect-keplr-wallet="connectKeplrSourceWallet"
        />
      </div>
      <div class="signup-form__item">
        <LabelField
          id="cosmos-recieve"
          class="signup-form__label"
          title="COSMOS Receive Address"
        />
        <TextField
          id="cosmos-recieve"
          v-model.trim="cosmosReceiveAddress"
          name="cosmos-recieve"
          required
          class="signup-form__input"
          tooltip-text=""
          type="text"
          connect-wallet-btn="keplr"
          :error="!!isCosmosRecieveAddressError"
          :error-txt="cosmosRecieveAddressErrorText"
          @connect-keplr-wallet="connectKeplrCosmosWallet"
        />
      </div>
    </div>
    <div class="settings__top-block-button">
      <ButtonBase :loading="confirmLoader" @click="saveNewWallets"
        >Save</ButtonBase
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import metamask from '@/mixins/metamask/metamask.js';
import keplr from '@/mixins/keplr/keplr.js';
import converter from 'convert-bech32-address';
import LabelField from '@/elements/LabelField';
import walletsValidation from '@/mixins/validation/forms/settingsWallets.js';

export default {
  components: { LabelField },
  mixins: [metamask, keplr, walletsValidation],
  props: {},

  data() {
    return {
      bscRecieveAddress: '',
      srcxRecieveAddress: '',
      sourceRecieveAddress: '',
      cosmosReceiveAddress: '',
      confirmLoader: false,
    };
  },
  computed: {
    ...mapGetters(['getProfile', 'getErrors']),
    updateData() {
      const data = {};

      if (!!this.bscRecieveAddress) {
        data.bsc_receive_address = this.bscRecieveAddress;
      }

      if (!!this.srcxRecieveAddress) {
        data.srcx_receive_address = this.srcxRecieveAddress;
      }

      if (!!this.sourceRecieveAddress) {
        data.source_receive_address = this.sourceRecieveAddress;
      }

      if (!!this.cosmosReceiveAddress) {
        data.cosmos_receive_address = this.cosmosReceiveAddress;
      }

      return data;
    },
  },
  created() {
    this.bscRecieveAddress = this.getProfile.bsc_receive_address;
    this.srcxRecieveAddress = this.getProfile.srcx_receive_address;
    this.sourceRecieveAddress = this.getProfile.source_receive_address;
    this.cosmosReceiveAddress = this.getProfile.cosmos_receive_address;
  },
  methods: {
    async connectMetamaskBSCWallet() {
      this.bscRecieveAddress = await this.getMetamaskAddress();
    },
    async connectMetamaskSRCXWallet() {
      this.srcxRecieveAddress = await this.getMetamaskAddress();
    },
    async connectKeplrSourceWallet() {
      this.sourceRecieveAddress = await this.getKeplrAddress('source');
    },
    async connectKeplrCosmosWallet() {
      this.cosmosReceiveAddress = await this.getKeplrAddress('cosmos');
    },
    convertSourceAddressOnMobile(event) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) &&
        event.target.value !== ''
      ) {
        const result = converter.lookup(event.target.value, 'source');
        console.log(result);
        this.sourceRecieveAddress = result;
      }
    },
    async saveNewWallets() {
      if (this.isInvalidForm) {
        this.showValidationErrors();
        return;
      }
      this.confirmLoader = true;
      await this.$store.dispatch('editWallets', this.updateData);
      this.confirmLoader = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.change-password
  display: flex
  flex-direction: column
  background-color: $background-profile-color
  padding: 2.4rem
  border-radius: 8px
  gap: 2.4rem

.settings__top-block-title
  @include xl
    text-align: center

.settings__top-block-profile
  display: grid
  grid-template-columns: repeat(3, 1fr)
  gap: 2.4rem
  @include xl
    grid-template-columns: 1fr
    width: 100%
    max-width: 35rem
    margin: 0 auto
    justify-content: center
    flex-wrap: wrap
  & div
    width: 100%
    min-width: 32rem
    @include m
      min-width: 15rem

.settings__top-block-button
  margin-left: auto
  @include xl
    margin: 0 auto
</style>
