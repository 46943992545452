<template>
  <div class="change-password">
    <h3 class="settings__top-block-title">Edit profile</h3>
    <div class="settings__top-block-profile">
      <div class="signup-form__item">
        <LabelField
          id="firstname"
          class="signup-form__label"
          title="First name"
        />
        <TextField
          id="firstname"
          v-model.trim="firstName"
          name="firstname"
          required
          class="signup-form__input"
          type="text"
          :error="isFirstNameError"
          :error-txt="firstNameErrorText"
          tooltip-text=""
        />
      </div>
      <div class="signup-form__item">
        <LabelField
          id="lastname"
          class="signup-form__label"
          title="Last name"
        />
        <TextField
          id="lastname"
          v-model.trim="lastName"
          name="lastname"
          required
          class="signup-form__input"
          type="text"
          :error="isLastNameError"
          :error-txt="lastNameErrorText"
          tooltip-text=""
        />
      </div>
      <div class="signup-form__item">
        <LabelField id="email" class="signup-form__label" title="E-Mail" />
        <TextField
          id="email"
          v-model.trim="email"
          name="email"
          required
          class="auth-form__input"
          type="text"
          :error="isEmailError"
          :error-txt="emailErrorText"
          tooltip-text=""
          @focus="resetServerError('email')"
        />
      </div>
    </div>
    <div class="settings__top-block-button">
      <ButtonBase :loading="confirmLoader" @click="saveNewData"
        >Save</ButtonBase
      >
    </div>
  </div>
</template>

<script>
import settingsEditProfileValidation from '@/mixins/validation/forms/settingsEditProfile.js';
import { mapGetters } from 'vuex';
import LabelField from '@/elements/LabelField';

export default {
  components: { LabelField },
  mixins: [settingsEditProfileValidation],
  props: {},
  data() {
    return {
      email: '',
      lastName: '',
      firstName: '',
      confirmLoader: false,
    };
  },
  computed: {
    ...mapGetters(['getProfile', 'getErrors']),
    updateData() {
      const data = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
      };

      return data;
    },
  },
  created() {
    this.email = this.getProfile.email;
    this.lastName = this.getProfile.last_name;
    this.firstName = this.getProfile.first_name;
  },
  methods: {
    async saveNewData() {
      if (this.isInvalidForm) {
        this.showValidationErrors();
        return;
      }
      this.confirmLoader = true;
      const res = await this.$store.dispatch('editProfile', this.updateData);
      this.confirmLoader = false;
      if (res) this.hideValidationErrors();
    },
  },
};
</script>

<style lang="sass" scoped>
.change-password
  display: flex
  flex-direction: column
  background-color: $background-profile-color
  padding: 2.4rem
  border-radius: 8px
  gap: 2.4rem

.settings__top-block-title
  @include xl
    text-align: center

.settings__top-block-profile
  display: grid
  grid-template-columns: repeat(3, 1fr)
  gap: 2.4rem
  @include xl
    grid-template-columns: 1fr
    width: 100%
    max-width: 35rem
    margin: 0 auto
    justify-content: center
    flex-wrap: wrap
  & div
    width: 100%
    min-width: 32rem
    @include m
      min-width: 15rem

.settings__top-block-button
  margin-left: auto
  @include xl
    margin: 0 auto
</style>
