import firstNameValidation from '@/mixins/validation/fields/firstName';
import lastNameValidation from '@/mixins/validation/fields/lastName';
import emailValidation from '@/mixins/validation/fields/email';
import validationsMethods from '@/mixins/validation/common/validationMethods';

export default {
  mixins: [
    firstNameValidation,
    lastNameValidation,
    emailValidation,
    validationsMethods,
  ],
  validations: {
    ...firstNameValidation.validations,
    ...lastNameValidation.validations,
    ...emailValidation.validations,
  },
};
