<template>
  <div class="settings">
    <div class="settings__header">
      <h3 class="settings__header-title">Settings</h3>
    </div>
    <div class="user-data__block">
      <DashboardUserData edit />
      <div class="dashboard__qr">
        <DashboardQR />
      </div>
    </div>
    <DashboardEditProfile />
    <DashboardEditWallets />
    <DashboardChangePassword />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardUserData from '@/components/Profile/DashboardUserData.vue';
import DashboardUserBalance from '@/components/Profile/DashboardUserBalance';
import DashboardEditProfile from '@/components/Profile/DashboardEditProfile.vue';
import DashboardEditWallets from '@/components/Profile/DashboardEditWallets.vue';
import DashboardChangePassword from '@/components/Profile/DashboardChangePassword.vue';
import DashboardQR from '@/components/Profile/DashboardQR.vue';

export default {
  components: {
    DashboardUserData,
    DashboardUserBalance,
    DashboardQR,
    DashboardChangePassword,
    DashboardEditProfile,
    DashboardEditWallets,
  },

  computed: {
    ...mapGetters(['getProfile', 'getErrors', 'getDashboardSettings']),
  },
  async created() {},
  methods: {},
};
</script>

<style lang="sass" scoped>
.settings
  padding: 2.4rem
  display: flex
  flex-direction: column
  gap: 2.4rem
  flex: 0 0 100%
  z-index: 3
  @include m
    padding: 0

.settings__header
  display: flex
  justify-content: space-between
  align-items: center
  gap: 2.4rem
  @include xl
    display: none

.settings__header-title
  margin-right: auto
  font-size: 3.2rem
  line-height: 3.8rem
  font-weight: 600

.user-data__block
  display: flex
  justify-content: space-between
  gap: 2.4rem
  @include m
    flex-wrap: wrap

.dashboard__qr
  @include xl
    display: none
</style>
