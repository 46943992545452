import validationsMethods from '@/mixins/validation/common/validationMethods';
import bscCoinValidation from '@/mixins/validation/fields/bsc_coin';
import srcxCoinValidation from '@/mixins/validation/fields/srcx_coin';
import cosmosCoinValidation from '@/mixins/validation/fields/cosmos';
import sourceCoinValidation from '@/mixins/validation/fields/source';

export default {
  mixins: [
    validationsMethods,
    bscCoinValidation,
    srcxCoinValidation,
    cosmosCoinValidation,
    sourceCoinValidation,
  ],
  validations: {
    ...bscCoinValidation.validations,
    ...srcxCoinValidation.validations,
    ...sourceCoinValidation.validations,
    ...cosmosCoinValidation.validations,
  },
};
